<template>
  <div v-intersect="onIntersection" class="image-container">
    <img
      ref="placeholder"
      class="placeholder"
      alt="placeholder"
      :src="placeholder"
    >
    <img
      v-if="sources"
      v-bind="{ ...$attrs, ...sources }"
      class="image"
      :class="[ { loading }, fit ]"
      @load="onLoad"
    >
    <juit-spinner v-if="loading" class="large max-w-full p-2" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'

  // Placeholders and respective ratios
  const placeholders = {
    '4x3': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAQAAAAe/WZNAAAADklEQVR42mNkgAJGDAYAAFEABCaLYqoAAAAASUVORK5CYII=',
    '5x3': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAADCAQAAADxPw1zAAAADklEQVR42mNkgANGrEwAAGMABP93vXMAAAAASUVORK5CYII=',
    '1x1': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
  }
  const ratios: Record<keyof typeof placeholders, number> = {
    '4x3': 1 * 4 / 3,
    '5x3': 1 * 5 / 3,
    '1x1': 1,
  }

  export default defineComponent({
    inheritAttrs: false,
    props: {
      src: {
        type: String as PropType<string | null | undefined>,
        default: undefined,
      },
      ratio: {
        type: String as PropType<keyof typeof placeholders>,
        default: '4x3',
      },
      contentType: {
        type: String as PropType<string | undefined>,
        default: undefined,
      },
      quality: {
        type: Number,
        required: false,
        default: 75,
        validator: (q: number) => (q >= 0) && (q <= 100),
      },
      // this needs to be a prop, as we use it also to tell contentful
      // _how_ to rescale the image to our intended size...
      fit: {
        type: String as PropType<'cover' | 'contain'>,
        default: 'cover',
      },
    },

    data() {
      return {
        sources: undefined as Record<string, string> | undefined,
        placeholder: placeholders[this.ratio],
        visible: false,
        loading: false,
        loaded: false,
      }
    },

    watch: {
      src() {
        this.sources = undefined
        this.loading = false
        this.onIntersection(this.visible)
      },
    },

    methods: {
      onIntersection(visible: boolean) {
        if (visible && this.src && (! this.sources)) {
          // The CloudFlare Images URL. We're using our "juit.gmbh" domain and
          // options are documented here:
          // https://developers.cloudflare.com/images/transform-images/transform-via-url/
          const makeCloudflareURL = (imageURL: string, params: Record<string, string | number>): string => {
            const options = Object.entries(params).map(([ key, value ]) => `${key}=${value}`).join(',')
            return `https://juit.gmbh/cdn-cgi/image/${options}/${imageURL}`
          }

          // SVG images are downloaded "as-is"
          if (this.contentType === 'image/svg+xml') {
            this.sources = { src: this.src }
            this.loading = true
            return
          }

          // Base all images out of the Contentful CDN (our account), if they're
          // starting with a different URL, then we just pass them through
          const src = new URL(this.src, 'https://images.ctfassets.net/a4btwfkreom2/').href
          if (! src.startsWith('https://images.ctfassets.net/a4btwfkreom2/')) {
            this.sources = { src }
            this.loading = true
            return
          }

          // Attributes bound to our <img> tag
          const sources = {} as Record<string, string>

          // Parameters to be passed to the CloudFlare Image Resizing service
          const params = {
            format: 'auto', // let CloudFlare decide the best format
            fit: this.fit, // CloudFlare supports "cover" and "contain"
            quality: this.quality, // Cloudflare supports 0-100
          } as Record<string, string | number>

          // Calculate width and height of the source / sourceset images to
          // request to CloudFlare Images. We calculate everything according
          // to our aspect ratio and constrain to a 50px based grid
          const placeholder = this.$refs['placeholder'] as HTMLImageElement
          const { offsetWidth: w, offsetHeight: h } = placeholder
          if (w & h) {
            // Scale width and height according to ratio vertically and horizontally
            const [ vw, vh ] = [ h * ratios[this.ratio], h ]
            const [ hw, hh ] = [ w, w / ratios[this.ratio] ]
            const [ vsize, hsize ] = [ vw * vh, hw * hh ]

            // The image width and height is the bigger (or smaller) if the fit
            // is cover (or contain)
            const [ iw, ih ] =
              this.fit === 'cover' ?
                vsize > hsize ? [ vw, vh ] : [ hw, hh ] :
                vsize > hsize ? [ hw, hh ] : [ vw, vh ]

            // Calculate image width and height to the next step (50px)
            const s = 50
            const [ sw, sh ] = [
              (Math.floor(iw / s) + (iw % s ? 1 : 0)) * s,
              (Math.floor(ih / s) + (ih % s ? 1 : 0)) * s,
            ]

            // Scale stepped width and height according to ratio
            const [ svw, svh ] = [ sh * ratios[this.ratio], sh ]
            const [ shw, shh ] = [ sw, sw / ratios[this.ratio] ]
            const [ svsize, shsize ] = [ svw * svh, shw * shh ]

            // The request width and height is the bigger of the stepped ones
            const [ rw, rh ] = svsize > shsize ?
              [ Math.floor(svw), Math.floor(svh) ] :
              [ Math.floor(shw), Math.floor(shh) ]

            // Inject the calculated witdth and height into the parameters
            params.width = rw
            params.height = rh

            // Calculate the scaled "srcset" for the image (we use the "dpr"
            // parameter from CloudFlare to scale the image)
            sources.srcset = [ 2, 1.5, 1 ].map((scale) => {
              const scaled = makeCloudflareURL(src, { ...params, dpr: scale })
              return `${scaled} ${scale}x`
            }).filter((source) => !! source).join(', ')
          }

          // The "src" attribute is the plain CloudFlare URL
          sources.src = makeCloudflareURL(src, params)

          this.loading = true
          this.sources = sources
        }
        this.visible = visible
      },
      onLoad(event: Event) {
        this.loading = false
        setTimeout(() => this.loaded = true, 350)
      },
    },
  })
</script>

<style scoped lang="pcss">
  div.image-container {
    @apply opacity-100 w-full h-full relative;
    overflow: hidden;
  }

  img.placeholder {
    @apply w-full h-full object-cover;
  }

  img.image {
    @apply w-full absolute pointer-events-none;
    @apply transition-opacity duration-300;
    @apply opacity-100;

    &.cover {
      @apply w-full absolute h-full top-0 left-0;
      @apply object-cover;
    }

    &.contain {
      @apply w-full absolute h-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
      @apply object-contain;
    }

    &.loading {
      @apply opacity-0;
    }
  }
</style>
